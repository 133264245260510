import React, {useContext} from 'react';
import Container from '@components/atoms/Container';
import Image from '@components/atoms/Image';
import TrackingLink from '@components/atoms/TrackingLink';
import CmsContext from '@containers/CMS/CmsContext';
import {useLanguageContext} from '@helpers/LanguageContext';
import pageUrlCleaner from '@helpers/pageUrlCleaner';
import dictionary from '@netlify/site-settings/dictionary.yml';
import {useStaticQuery, graphql} from 'gatsby';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

// eslint-disable-next-line
import HudlArrow from "-!svg-react-loader!@images/hudl-arrow.svg";

import './style.related-articles.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const RelatedArticlesBlock = ({
    title,
    articlesList,
    spacing
}) => {
    const {pageLanguageCode, pageLanguageName} = useLanguageContext();

    const isCms = useContext(CmsContext);
    if (isCms) {
    // No static queries allowed in CMS preview, so return simple image.
        return (
            <>
                <h3>Related Articles Block</h3>
                <p>CMS preview unavaiable for Related Articles Block as uses Static Query at build time.</p>
            </>
        );
    }

    // Get all Article nodes.
    const data = useStaticQuery(graphql`
        query {
            allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(case-study)/"}}) {
                nodes {
                    frontmatter {
                        path
                        language
                        title
                        featuredImage {
                            image
                            imageAlt
                            imageTitle
                        }
                    }
                }
            }
        }
    `);

    // Get array of Article paths to include.
    const articlePaths = articlesList.map(item => item.article);

    // Filter data nodes to only include selected Article nodes.
    const articleNodes = articlePaths.map(path =>
        data.allMarkdownRemark.nodes.find((nodeItem) =>
            pageUrlCleaner({path: nodeItem.frontmatter.path}) === pageUrlCleaner({path}) && nodeItem.frontmatter.language === pageLanguageCode
        ))
    // ToDo: Investigate this bug
        .filter(item => typeof item !== 'undefined');

    if (articleNodes.length === 0) {
    // There are no articles to render.
        return null;
    }

    // Create list of Article items.
    const articleItems = articlesList.map((item, index) => {
        // Check to make sure that there is a Article to get data from.
        // An empty/invalid Article can be returned if it is part of a list but then deleted.
        if (typeof articlesList[index] === 'undefined' || typeof articleNodes[index] === 'undefined' || articleNodes[index] === null) {
            return null;
        }

        const {frontmatter: {
            title: itemTitle, path, featuredImage, language
        }} = articleNodes[index];
        return (
            <div className="hudl-related-articles__article hudl-margin-top--md hudl-margin--normal" key={index.toString()}>
                <div className="hudl-related-articles__article-image">
                    <Image imgsrc={featuredImage.image} title={featuredImage.imageTitle} alt={featuredImage.imageAlt} aspectRatio={4/3} />
                </div>
                <div className="hudl-related-articles__article-content hudl-padding--md hudl-padding--sm--desktop">
                    <h3 className="hudl-related-articles__article-title hudl-margin-top--none hudl-margin-bottom--sm">{itemTitle}</h3>
                    <TrackingLink component="related_article_block" to={`${language}/${path}`} className="hudl-related-articles__article-link">
                        <HudlArrow className="hudl-margin-right--xs" />
                        {dictionary[pageLanguageName].readMore}
                    </TrackingLink>
                </div>
            </div>
        );
    });

    const settings = {
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        focusOnChange: true,
        accessibility: true,
        initialSlide: -3,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    const marginTopClass = spacing && spacing.marginTop ? 'hudl-block__margin-top' : '';
    const marginBottomClass = spacing && spacing.marginBottom ? 'hudl-block__margin-bottom' : '';
    const classes = [ 'hudl-related-articles', 'hudl-padding-y--xxl', marginTopClass, marginBottomClass ];

    // Render the component.
    return (
        <div className={classes.join(' ')}>
            <Container size="large">
                <h2 className="hudl-gamma-type hudl-margin-bottom--none">{title}</h2>
                <Slider className="hudl-related-articles__slider" {...settings}>
                    {articleItems}
                </Slider>
            </Container>
        </div>
    );
};

export default RelatedArticlesBlock;

RelatedArticlesBlock.propTypes = {
    title: PropTypes.string.isRequired,
    articlesList: PropTypes.arrayOf(PropTypes.shape({
        article: PropTypes.string.isRequired
    }))
};

RelatedArticlesBlock.defaultProps = {
    articlesList: []
};
