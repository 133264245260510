const pageUrlCleaner = ({path = '/'}) => {
    const splitPath = path.split('/');

    splitPath.shift();

    const cleanPath = splitPath.join('/');

    return cleanPath;

};

export default pageUrlCleaner;
